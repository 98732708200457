.contact-profile {
    .profile__wrapper {
        position: relative;
        z-index: 1;

        text-align: center;
        &:after {
            position: absolute;
            z-index: -1;
            top: calc(50% - .4rem);
            left: 0;

            width: 100%;
            height: .4rem;

            content: '';

            background-color: white;
        }
    }
    .profile__image {
        display: inline-block;

        width: 13rem;
        height: 13rem;

        border-radius: 10rem;
        background-position: top;
        background-size: cover;
    }
    h3 {
        margin: 3rem 0;

        text-align: center;
    }
    .profile__info-header,
    .profile__edit-header {
        padding-bottom: 1rem;

        border-bottom: .1rem solid $grey;
    }
    .profile__info-header {
        position: relative;

        padding-right: 3rem;
        a {
            font-size: 1.4rem;

            position: absolute;
            top: -1.3rem;
            right: -1.5rem;

            padding: 1.5rem;

            color: $dark_blue;
            &:hover {
                color: $highlight_blue;
            }
        }
    }
    h5 {
        font-family: $font__PFDmed;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2rem;

        margin: 0;

        color: $highlight_blue;
    }
    p {
        margin-bottom: 0;
        padding: 1.5rem 0;

        border-top: .1rem solid $grey;
        strong {
            font-size: 1.4rem;
            line-height: 1.8rem;

            display: block;
        }
        &.profile-buttons {
            text-align: center;
            a {
                width: 21rem;
                margin-bottom: 0.5rem;
            }
        }
    }
    form {
        > label,
        input,
        select,
        textarea {
            display: block;
            width: 100%;
        }
        input:not([type='submit']),
        select,
        textarea {
            padding: .2rem;

            border: none;
            background-color: transparent;
            &:focus {
                outline: .1rem dotted $highlight_blue;
            }
        }
        > label {
            padding-top: 1.5rem;

            border-top: .1rem solid $grey;
            span {
                font-size: 1.2rem;
                font-weight: 400;
            }
            + input, + select, + textarea {
                margin-bottom: 1.5rem;
            }
        }
        label[for='contact_method'] {
            position: relative;
            &:after {
                font-family: 'icomoon';
                font-size: 1.2rem;

                position: absolute;
                top: 4.2rem;
                right: 1rem;

                content: '\f078';
                pointer-events: none;
            }
        }
        select {
            -webkit-appearance: none;
               -moz-appearance: none;
                    appearance: none;
        }
        textarea {
            min-height: 6rem;
            padding: 0;

            resize: none;
        }
        fieldset#subscription {
            padding: .5rem 0 0 1rem;
            label {
                display: block;
            }

            div.subscription-description {
              font-size: 1.5rem;
              line-height: 1.6rem;
              margin-left: 5rem;
              margin-bottom: 1.5rem;
              font-style: italic;
            }
        }
        input[type='radio'] {
            display: none !important;
        }
        input[type='radio'] + label {
            font-weight: 400;

            position: relative;

            padding-left: 2.1rem !important;

            cursor: pointer;
            &:before {
                display: inline-block;

                width: 1.1rem;
                height: 1.1rem;

                content: '';

                border: .1rem solid $dark_blue;
                border-radius: 100%;
                background-color: transparent;

                @extend %transition;

                position: absolute;
                top: .5rem;
                left: 0;

            }
        }
        input[type='radio']:checked + label:before {
            background-color: $dark_blue;
        }
        input[type='submit'] {
            margin: 3rem auto;
            width: auto;
            background-color: transparent;
        }
        input::-webkit-contacts-auto-fill-button {
            position: absolute;
            right: 0;

            display: none !important;
            visibility: hidden;

            pointer-events: none;
        }
        input[type='checkbox'] {
          display: inline;
          width: auto;
          margin-right: 1.5rem;
        }
    }
}

.profile-file-upload {
    .file-preview-img-container {
        width: 13rem;
        height: 13rem;

        border-radius: 10rem;
        background-position: top;
        background-size: cover;

        margin: auto;
    }
}

@media (min-width: 768px) {
    .contact-profile {
        .profile {
            margin-top: 3rem;
        }
        .profile__wrapper {
            &:after {
                background-color: $light_grey_alt;
            }
        }
        h5 {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
        p {
            strong {
                font-size: 1.6rem;
                line-height: 2rem;

                display: block;
            }
        }
        form {
            > label {
                span {
                    font-size: 1.4rem;
                }
            }
            label[for='contact_method'] {
                &:after {
                    top: 4.5rem;
                }
            }
            textarea {
                min-height: 7rem;
            }
            input[type='radio'] + label:before {
                top: .6rem;
            }
        }
    }
}
