@font-face {
    font-family: 'Market';

    src: url('../assets/fonts/market-regular-opentype.otf');
}

@font-face {
    font-family: 'PFDin-med';
    font-weight: 500;
    font-style: normal;

    src: url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-medium-webfont.eot');
    src: url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-medium-webfont.woff2') format('woff2'),
    url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-medium-webfont.woff') format('woff'),
    url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-medium-webfont.ttf') format('truetype'),
    url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-medium-webfont.svg#pf_din_text_cond_promedium') format('svg');
}

@font-face {
    font-family: 'PFDin';
    font-weight: 300;
    font-style: normal;

    src: url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-light-webfont.eot');
    src: url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-light-webfont.woff2') format('woff2'),
    url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-light-webfont.woff') format('woff'),
    url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-light-webfont.ttf') format('truetype'),
    url('../assets/fonts/PFD/PFDinCondPro/pfdintextcondpro-light-webfont.svg#pf_din_text_cond_prolight') format('svg');
}


/* ICONS */
@font-face {
    font-family: 'icomoon';
    font-weight: normal;
    font-style: normal;

    src: url('../assets/fonts/icons/icomoon.eot?j8r2em');
    src: url('../assets/fonts/icons/icomoon.eot?j8r2em#iefix') format('embedded-opentype'),
    url('../assets/fonts/icons/icomoon.ttf?j8r2em') format('truetype'),
    url('../assets/fonts/icons/icomoon.woff?j8r2em') format('woff'),
    url('../assets/fonts/icons/icomoon.svg?j8r2em#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;

    text-transform: none;

    speak: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-asterisk:before {
    content: '\f069';
}
.icon-plus:before {
    content: '\f067';
}
.icon-question:before {
    content: '\f128';
}
.icon-minus:before {
    content: '\f068';
}
.icon-glass:before {
    content: '\f000';
}
.icon-music:before {
    content: '\f001';
}
.icon-search:before {
    content: '\f002';
}
.icon-envelope-o:before {
    content: '\f003';
}
.icon-heart:before {
    content: '\f004';
}
.icon-star:before {
    content: '\f005';
}
.icon-star-o:before {
    content: '\f006';
}
.icon-user:before {
    content: '\f007';
}
.icon-film:before {
    content: '\f008';
}
.icon-th-large:before {
    content: '\f009';
}
.icon-th:before {
    content: '\f00a';
}
.icon-th-list:before {
    content: '\f00b';
}
.icon-check:before {
    content: '\f00c';
}
.icon-close:before {
    content: '\f00d';
}
.icon-remove:before {
    content: '\f00d';
}
.icon-times:before {
    content: '\f00d';
}
.icon-search-plus:before {
    content: '\f00e';
}
.icon-search-minus:before {
    content: '\f010';
}
.icon-power-off:before {
    content: '\f011';
}
.icon-signal:before {
    content: '\f012';
}
.icon-cog:before {
    content: '\f013';
}
.icon-gear:before {
    content: '\f013';
}
.icon-trash-o:before {
    content: '\f014';
}
.icon-home:before {
    content: '\f015';
}
.icon-file-o:before {
    content: '\f016';
}
.icon-clock-o:before {
    content: '\f017';
}
.icon-road:before {
    content: '\f018';
}
.icon-download:before {
    content: '\f019';
}
.icon-arrow-circle-o-down:before {
    content: '\f01a';
}
.icon-arrow-circle-o-up:before {
    content: '\f01b';
}
.icon-inbox:before {
    content: '\f01c';
}
.icon-play-circle-o:before {
    content: '\f01d';
}
.icon-repeat:before {
    content: '\f01e';
}
.icon-rotate-right:before {
    content: '\f01e';
}
.icon-refresh:before {
    content: '\f021';
}
.icon-list-alt:before {
    content: '\f022';
}
.icon-lock:before {
    content: '\f023';
}
.icon-flag:before {
    content: '\f024';
}
.icon-headphones:before {
    content: '\f025';
}
.icon-volume-off:before {
    content: '\f026';
}
.icon-volume-down:before {
    content: '\f027';
}
.icon-volume-up:before {
    content: '\f028';
}
.icon-qrcode:before {
    content: '\f029';
}
.icon-barcode:before {
    content: '\f02a';
}
.icon-tag:before {
    content: '\f02b';
}
.icon-tags:before {
    content: '\f02c';
}
.icon-book:before {
    content: '\f02d';
}
.icon-bookmark:before {
    content: '\f02e';
}
.icon-print:before {
    content: '\f02f';
}
.icon-camera:before {
    content: '\f030';
}
.icon-font:before {
    content: '\f031';
}
.icon-bold:before {
    content: '\f032';
}
.icon-italic:before {
    content: '\f033';
}
.icon-text-height:before {
    content: '\f034';
}
.icon-text-width:before {
    content: '\f035';
}
.icon-align-left:before {
    content: '\f036';
}
.icon-align-center:before {
    content: '\f037';
}
.icon-align-right:before {
    content: '\f038';
}
.icon-align-justify:before {
    content: '\f039';
}
.icon-list:before {
    content: '\f03a';
}
.icon-dedent:before {
    content: '\f03b';
}
.icon-outdent:before {
    content: '\f03b';
}
.icon-indent:before {
    content: '\f03c';
}
.icon-video-camera:before {
    content: '\f03d';
}
.icon-image:before {
    content: '\f03e';
}
.icon-photo:before {
    content: '\f03e';
}
.icon-picture-o:before {
    content: '\f03e';
}
.icon-pencil:before {
    content: '\f040';
}
.icon-map-marker:before {
    content: '\f041';
}
.icon-adjust:before {
    content: '\f042';
}
.icon-tint:before {
    content: '\f043';
}
.icon-edit:before {
    content: '\f044';
}
.icon-pencil-square-o:before {
    content: '\f044';
}
.icon-share-square-o:before {
    content: '\f045';
}
.icon-check-square-o:before {
    content: '\f046';
}
.icon-arrows:before {
    content: '\f047';
}
.icon-step-backward:before {
    content: '\f048';
}
.icon-fast-backward:before {
    content: '\f049';
}
.icon-backward:before {
    content: '\f04a';
}
.icon-play:before {
    content: '\f04b';
}
.icon-pause:before {
    content: '\f04c';
}
.icon-stop:before {
    content: '\f04d';
}
.icon-forward:before {
    content: '\f04e';
}
.icon-fast-forward:before {
    content: '\f050';
}
.icon-step-forward:before {
    content: '\f051';
}
.icon-eject:before {
    content: '\f052';
}
.icon-chevron-left:before {
    content: '\f053';
}
.icon-chevron-right:before {
    content: '\f054';
}
.icon-plus-circle:before {
    content: '\f055';
}
.icon-minus-circle:before {
    content: '\f056';
}
.icon-times-circle:before {
    content: '\f057';
}
.icon-check-circle:before {
    content: '\f058';
}
.icon-question-circle:before {
    content: '\f059';
}
.icon-info-circle:before {
    content: '\f05a';
}
.icon-crosshairs:before {
    content: '\f05b';
}
.icon-times-circle-o:before {
    content: '\f05c';
}
.icon-check-circle-o:before {
    content: '\f05d';
}
.icon-ban:before {
    content: '\f05e';
}
.icon-arrow-left:before {
    content: '\f060';
}
.icon-arrow-right:before {
    content: '\f061';
}
.icon-arrow-up:before {
    content: '\f062';
}
.icon-arrow-down:before {
    content: '\f063';
}
.icon-mail-forward:before {
    content: '\f064';
}
.icon-share:before {
    content: '\f064';
}
.icon-expand:before {
    content: '\f065';
}
.icon-compress:before {
    content: '\f066';
}
.icon-exclamation-circle:before {
    content: '\f06a';
}
.icon-gift:before {
    content: '\f06b';
}
.icon-leaf:before {
    content: '\f06c';
}
.icon-fire:before {
    content: '\f06d';
}
.icon-eye:before {
    content: '\f06e';
}
.icon-eye-slash:before {
    content: '\f070';
}
.icon-exclamation-triangle:before {
    content: '\f071';
}
.icon-warning:before {
    content: '\f071';
}
.icon-plane:before {
    content: '\f072';
}
.icon-calendar:before {
    content: '\f073';
}
.icon-random:before {
    content: '\f074';
}
.icon-comment:before {
    content: '\f075';
}
.icon-magnet:before {
    content: '\f076';
}
.icon-chevron-up:before {
    content: '\f077';
}
.icon-chevron-down:before {
    content: '\f078';
}
.icon-retweet:before {
    content: '\f079';
}
.icon-shopping-cart:before {
    content: '\f07a';
}
.icon-folder:before {
    content: '\f07b';
}
.icon-folder-open:before {
    content: '\f07c';
}
.icon-arrows-v:before {
    content: '\f07d';
}
.icon-arrows-h:before {
    content: '\f07e';
}
.icon-bar-chart:before {
    content: '\f080';
}
.icon-bar-chart-o:before {
    content: '\f080';
}
.icon-twitter-square:before {
    content: '\f081';
}
.icon-facebook-square:before {
    content: '\f082';
}
.icon-camera-retro:before {
    content: '\f083';
}
.icon-key:before {
    content: '\f084';
}
.icon-cogs:before {
    content: '\f085';
}
.icon-gears:before {
    content: '\f085';
}
.icon-comments:before {
    content: '\f086';
}
.icon-thumbs-o-up:before {
    content: '\f087';
}
.icon-thumbs-o-down:before {
    content: '\f088';
}
.icon-star-half:before {
    content: '\f089';
}
.icon-heart-o:before {
    content: '\f08a';
}
.icon-sign-out:before {
    content: '\f08b';
}
.icon-linkedin-square:before {
    content: '\f08c';
}
.icon-thumb-tack:before {
    content: '\f08d';
}
.icon-external-link:before {
    content: '\f08e';
}
.icon-sign-in:before {
    content: '\f090';
}
.icon-trophy:before {
    content: '\f091';
}
.icon-github-square:before {
    content: '\f092';
}
.icon-upload:before {
    content: '\f093';
}
.icon-lemon-o:before {
    content: '\f094';
}
.icon-phone:before {
    content: '\f095';
}
.icon-square-o:before {
    content: '\f096';
}
.icon-bookmark-o:before {
    content: '\f097';
}
.icon-phone-square:before {
    content: '\f098';
}
.icon-twitter:before {
    content: '\f099';
}
.icon-facebook:before {
    content: '\f09a';
}
.icon-facebook-f:before {
    content: '\f09a';
}
.icon-github:before {
    content: '\f09b';
}
.icon-unlock:before {
    content: '\f09c';
}
.icon-credit-card:before {
    content: '\f09d';
}
.icon-feed:before {
    content: '\f09e';
}
.icon-rss:before {
    content: '\f09e';
}
.icon-hdd-o:before {
    content: '\f0a0';
}
.icon-bullhorn:before {
    content: '\f0a1';
}
.icon-bell-o:before {
    content: '\f0a2';
}
.icon-certificate:before {
    content: '\f0a3';
}
.icon-hand-o-right:before {
    content: '\f0a4';
}
.icon-hand-o-left:before {
    content: '\f0a5';
}
.icon-hand-o-up:before {
    content: '\f0a6';
}
.icon-hand-o-down:before {
    content: '\f0a7';
}
.icon-arrow-circle-left:before {
    content: '\f0a8';
}
.icon-arrow-circle-right:before {
    content: '\f0a9';
}
.icon-arrow-circle-up:before {
    content: '\f0aa';
}
.icon-arrow-circle-down:before {
    content: '\f0ab';
}
.icon-globe:before {
    content: '\f0ac';
}
.icon-wrench:before {
    content: '\f0ad';
}
.icon-tasks:before {
    content: '\f0ae';
}
.icon-filter:before {
    content: '\f0b0';
}
.icon-briefcase:before {
    content: '\f0b1';
}
.icon-arrows-alt:before {
    content: '\f0b2';
}
.icon-group:before {
    content: '\f0c0';
}
.icon-users:before {
    content: '\f0c0';
}
.icon-chain:before {
    content: '\f0c1';
}
.icon-link:before {
    content: '\f0c1';
}
.icon-cloud:before {
    content: '\f0c2';
}
.icon-flask:before {
    content: '\f0c3';
}
.icon-cut:before {
    content: '\f0c4';
}
.icon-scissors:before {
    content: '\f0c4';
}
.icon-copy:before {
    content: '\f0c5';
}
.icon-files-o:before {
    content: '\f0c5';
}
.icon-paperclip:before {
    content: '\f0c6';
}
.icon-floppy-o:before {
    content: '\f0c7';
}
.icon-save:before {
    content: '\f0c7';
}
.icon-square:before {
    content: '\f0c8';
}
.icon-bars:before {
    content: '\f0c9';
}
.icon-navicon:before {
    content: '\f0c9';
}
.icon-reorder:before {
    content: '\f0c9';
}
.icon-list-ul:before {
    content: '\f0ca';
}
.icon-list-ol:before {
    content: '\f0cb';
}
.icon-strikethrough:before {
    content: '\f0cc';
}
.icon-underline:before {
    content: '\f0cd';
}
.icon-table:before {
    content: '\f0ce';
}
.icon-magic:before {
    content: '\f0d0';
}
.icon-truck:before {
    content: '\f0d1';
}
.icon-pinterest:before {
    content: '\f0d2';
}
.icon-pinterest-square:before {
    content: '\f0d3';
}
.icon-google-plus-square:before {
    content: '\f0d4';
}
.icon-google-plus:before {
    content: '\f0d5';
}
.icon-money:before {
    content: '\f0d6';
}
.icon-caret-down:before {
    content: '\f0d7';
}
.icon-caret-up:before {
    content: '\f0d8';
}
.icon-caret-left:before {
    content: '\f0d9';
}
.icon-caret-right:before {
    content: '\f0da';
}
.icon-columns:before {
    content: '\f0db';
}
.icon-sort:before {
    content: '\f0dc';
}
.icon-unsorted:before {
    content: '\f0dc';
}
.icon-sort-desc:before {
    content: '\f0dd';
}
.icon-sort-down:before {
    content: '\f0dd';
}
.icon-sort-asc:before {
    content: '\f0de';
}
.icon-sort-up:before {
    content: '\f0de';
}
.icon-envelope:before {
    content: '\f0e0';
}
.icon-linkedin:before {
    content: '\f0e1';
}
.icon-rotate-left:before {
    content: '\f0e2';
}
.icon-undo:before {
    content: '\f0e2';
}
.icon-gavel:before {
    content: '\f0e3';
}
.icon-legal:before {
    content: '\f0e3';
}
.icon-dashboard:before {
    content: '\f0e4';
}
.icon-tachometer:before {
    content: '\f0e4';
}
.icon-comment-o:before {
    content: '\f0e5';
}
.icon-comments-o:before {
    content: '\f0e6';
}
.icon-bolt:before {
    content: '\f0e7';
}
.icon-flash:before {
    content: '\f0e7';
}
.icon-sitemap:before {
    content: '\f0e8';
}
.icon-umbrella:before {
    content: '\f0e9';
}
.icon-clipboard:before {
    content: '\f0ea';
}
.icon-paste:before {
    content: '\f0ea';
}
.icon-lightbulb-o:before {
    content: '\f0eb';
}
.icon-exchange:before {
    content: '\f0ec';
}
.icon-cloud-download:before {
    content: '\f0ed';
}
.icon-cloud-upload:before {
    content: '\f0ee';
}
.icon-user-md:before {
    content: '\f0f0';
}
.icon-stethoscope:before {
    content: '\f0f1';
}
.icon-suitcase:before {
    content: '\f0f2';
}
.icon-bell:before {
    content: '\f0f3';
}
.icon-coffee:before {
    content: '\f0f4';
}
.icon-cutlery:before {
    content: '\f0f5';
}
.icon-file-text-o:before {
    content: '\f0f6';
}
.icon-building-o:before {
    content: '\f0f7';
}
.icon-hospital-o:before {
    content: '\f0f8';
}
.icon-ambulance:before {
    content: '\f0f9';
}
.icon-medkit:before {
    content: '\f0fa';
}
.icon-fighter-jet:before {
    content: '\f0fb';
}
.icon-beer:before {
    content: '\f0fc';
}
.icon-h-square:before {
    content: '\f0fd';
}
.icon-plus-square:before {
    content: '\f0fe';
}
.icon-angle-double-left:before {
    content: '\f100';
}
.icon-angle-double-right:before {
    content: '\f101';
}
.icon-angle-double-up:before {
    content: '\f102';
}
.icon-angle-double-down:before {
    content: '\f103';
}
.icon-angle-left:before {
    content: '\f104';
}
.icon-angle-right:before {
    content: '\f105';
}
.icon-angle-up:before {
    content: '\f106';
}
.icon-angle-down:before {
    content: '\f107';
}
.icon-desktop:before {
    content: '\f108';
}
.icon-laptop:before {
    content: '\f109';
}
.icon-tablet:before {
    content: '\f10a';
}
.icon-mobile:before {
    content: '\f10b';
}
.icon-mobile-phone:before {
    content: '\f10b';
}
.icon-circle-o:before {
    content: '\f10c';
}
.icon-quote-left:before {
    content: '\f10d';
}
.icon-quote-right:before {
    content: '\f10e';
}
.icon-spinner:before {
    content: '\f110';
}
.icon-circle:before {
    content: '\f111';
}
.icon-mail-reply:before {
    content: '\f112';
}
.icon-reply:before {
    content: '\f112';
}
.icon-github-alt:before {
    content: '\f113';
}
.icon-folder-o:before {
    content: '\f114';
}
.icon-folder-open-o:before {
    content: '\f115';
}
.icon-smile-o:before {
    content: '\f118';
}
.icon-frown-o:before {
    content: '\f119';
}
.icon-meh-o:before {
    content: '\f11a';
}
.icon-gamepad:before {
    content: '\f11b';
}
.icon-keyboard-o:before {
    content: '\f11c';
}
.icon-flag-o:before {
    content: '\f11d';
}
.icon-flag-checkered:before {
    content: '\f11e';
}
.icon-terminal:before {
    content: '\f120';
}
.icon-code:before {
    content: '\f121';
}
.icon-mail-reply-all:before {
    content: '\f122';
}
.icon-reply-all:before {
    content: '\f122';
}
.icon-star-half-empty:before {
    content: '\f123';
}
.icon-star-half-full:before {
    content: '\f123';
}
.icon-star-half-o:before {
    content: '\f123';
}
.icon-location-arrow:before {
    content: '\f124';
}
.icon-crop:before {
    content: '\f125';
}
.icon-code-fork:before {
    content: '\f126';
}
.icon-chain-broken:before {
    content: '\f127';
}
.icon-unlink:before {
    content: '\f127';
}
.icon-info:before {
    content: '\f129';
}
.icon-exclamation:before {
    content: '\f12a';
}
.icon-superscript:before {
    content: '\f12b';
}
.icon-subscript:before {
    content: '\f12c';
}
.icon-eraser:before {
    content: '\f12d';
}
.icon-puzzle-piece:before {
    content: '\f12e';
}
.icon-microphone:before {
    content: '\f130';
}
.icon-microphone-slash:before {
    content: '\f131';
}
.icon-shield:before {
    content: '\f132';
}
.icon-calendar-o:before {
    content: '\f133';
}
.icon-fire-extinguisher:before {
    content: '\f134';
}
.icon-rocket:before {
    content: '\f135';
}
.icon-maxcdn:before {
    content: '\f136';
}
.icon-chevron-circle-left:before {
    content: '\f137';
}
.icon-chevron-circle-right:before {
    content: '\f138';
}
.icon-chevron-circle-up:before {
    content: '\f139';
}
.icon-chevron-circle-down:before {
    content: '\f13a';
}
.icon-html5:before {
    content: '\f13b';
}
.icon-css3:before {
    content: '\f13c';
}
.icon-anchor:before {
    content: '\f13d';
}
.icon-unlock-alt:before {
    content: '\f13e';
}
.icon-bullseye:before {
    content: '\f140';
}
.icon-ellipsis-h:before {
    content: '\f141';
}
.icon-ellipsis-v:before {
    content: '\f142';
}
.icon-rss-square:before {
    content: '\f143';
}
.icon-play-circle:before {
    content: '\f144';
}
.icon-ticket:before {
    content: '\f145';
}
.icon-minus-square:before {
    content: '\f146';
}
.icon-minus-square-o:before {
    content: '\f147';
}
.icon-level-up:before {
    content: '\f148';
}
.icon-level-down:before {
    content: '\f149';
}
.icon-check-square:before {
    content: '\f14a';
}
.icon-pencil-square:before {
    content: '\f14b';
}
.icon-external-link-square:before {
    content: '\f14c';
}
.icon-share-square:before {
    content: '\f14d';
}
.icon-compass:before {
    content: '\f14e';
}
.icon-caret-square-o-down:before {
    content: '\f150';
}
.icon-toggle-down:before {
    content: '\f150';
}
.icon-caret-square-o-up:before {
    content: '\f151';
}
.icon-toggle-up:before {
    content: '\f151';
}
.icon-caret-square-o-right:before {
    content: '\f152';
}
.icon-toggle-right:before {
    content: '\f152';
}
.icon-eur:before {
    content: '\f153';
}
.icon-euro:before {
    content: '\f153';
}
.icon-gbp:before {
    content: '\f154';
}
.icon-dollar:before {
    content: '\f155';
}
.icon-usd:before {
    content: '\f155';
}
.icon-inr:before {
    content: '\f156';
}
.icon-rupee:before {
    content: '\f156';
}
.icon-cny:before {
    content: '\f157';
}
.icon-jpy:before {
    content: '\f157';
}
.icon-rmb:before {
    content: '\f157';
}
.icon-yen:before {
    content: '\f157';
}
.icon-rouble:before {
    content: '\f158';
}
.icon-rub:before {
    content: '\f158';
}
.icon-ruble:before {
    content: '\f158';
}
.icon-krw:before {
    content: '\f159';
}
.icon-won:before {
    content: '\f159';
}
.icon-bitcoin:before {
    content: '\f15a';
}
.icon-btc:before {
    content: '\f15a';
}
.icon-file:before {
    content: '\f15b';
}
.icon-file-text:before {
    content: '\f15c';
}
.icon-sort-alpha-asc:before {
    content: '\f15d';
}
.icon-sort-alpha-desc:before {
    content: '\f15e';
}
.icon-sort-amount-asc:before {
    content: '\f160';
}
.icon-sort-amount-desc:before {
    content: '\f161';
}
.icon-sort-numeric-asc:before {
    content: '\f162';
}
.icon-sort-numeric-desc:before {
    content: '\f163';
}
.icon-thumbs-up:before {
    content: '\f164';
}
.icon-thumbs-down:before {
    content: '\f165';
}
.icon-youtube-square:before {
    content: '\f166';
}
.icon-youtube:before {
    content: '\f167';
}
.icon-xing:before {
    content: '\f168';
}
.icon-xing-square:before {
    content: '\f169';
}
.icon-youtube-play:before {
    content: '\f16a';
}
.icon-dropbox:before {
    content: '\f16b';
}
.icon-stack-overflow:before {
    content: '\f16c';
}
.icon-instagram:before {
    content: '\f16d';
}
.icon-flickr:before {
    content: '\f16e';
}
.icon-adn:before {
    content: '\f170';
}
.icon-bitbucket:before {
    content: '\f171';
}
.icon-bitbucket-square:before {
    content: '\f172';
}
.icon-tumblr:before {
    content: '\f173';
}
.icon-tumblr-square:before {
    content: '\f174';
}
.icon-long-arrow-down:before {
    content: '\f175';
}
.icon-long-arrow-up:before {
    content: '\f176';
}
.icon-long-arrow-left:before {
    content: '\f177';
}
.icon-long-arrow-right:before {
    content: '\f178';
}
.icon-apple:before {
    content: '\f179';
}
.icon-windows:before {
    content: '\f17a';
}
.icon-android:before {
    content: '\f17b';
}
.icon-linux:before {
    content: '\f17c';
}
.icon-dribbble:before {
    content: '\f17d';
}
.icon-skype:before {
    content: '\f17e';
}
.icon-foursquare:before {
    content: '\f180';
}
.icon-trello:before {
    content: '\f181';
}
.icon-female:before {
    content: '\f182';
}
.icon-male:before {
    content: '\f183';
}
.icon-gittip:before {
    content: '\f184';
}
.icon-gratipay:before {
    content: '\f184';
}
.icon-sun-o:before {
    content: '\f185';
}
.icon-moon-o:before {
    content: '\f186';
}
.icon-archive:before {
    content: '\f187';
}
.icon-bug:before {
    content: '\f188';
}
.icon-vk:before {
    content: '\f189';
}
.icon-weibo:before {
    content: '\f18a';
}
.icon-renren:before {
    content: '\f18b';
}
.icon-pagelines:before {
    content: '\f18c';
}
.icon-stack-exchange:before {
    content: '\f18d';
}
.icon-arrow-circle-o-right:before {
    content: '\f18e';
}
.icon-arrow-circle-o-left:before {
    content: '\f190';
}
.icon-caret-square-o-left:before {
    content: '\f191';
}
.icon-toggle-left:before {
    content: '\f191';
}
.icon-dot-circle-o:before {
    content: '\f192';
}
.icon-wheelchair:before {
    content: '\f193';
}
.icon-vimeo-square:before {
    content: '\f194';
}
.icon-try:before {
    content: '\f195';
}
.icon-turkish-lira:before {
    content: '\f195';
}
.icon-plus-square-o:before {
    content: '\f196';
}
.icon-space-shuttle:before {
    content: '\f197';
}
.icon-slack:before {
    content: '\f198';
}
.icon-envelope-square:before {
    content: '\f199';
}
.icon-wordpress:before {
    content: '\f19a';
}
.icon-openid:before {
    content: '\f19b';
}
.icon-bank:before {
    content: '\f19c';
}
.icon-institution:before {
    content: '\f19c';
}
.icon-university:before {
    content: '\f19c';
}
.icon-graduation-cap:before {
    content: '\f19d';
}
.icon-mortar-board:before {
    content: '\f19d';
}
.icon-yahoo:before {
    content: '\f19e';
}
.icon-google:before {
    content: '\f1a0';
}
.icon-reddit:before {
    content: '\f1a1';
}
.icon-reddit-square:before {
    content: '\f1a2';
}
.icon-stumbleupon-circle:before {
    content: '\f1a3';
}
.icon-stumbleupon:before {
    content: '\f1a4';
}
.icon-delicious:before {
    content: '\f1a5';
}
.icon-digg:before {
    content: '\f1a6';
}
.icon-pied-piper-pp:before {
    content: '\f1a7';
}
.icon-pied-piper-alt:before {
    content: '\f1a8';
}
.icon-drupal:before {
    content: '\f1a9';
}
.icon-joomla:before {
    content: '\f1aa';
}
.icon-language:before {
    content: '\f1ab';
}
.icon-fax:before {
    content: '\f1ac';
}
.icon-building:before {
    content: '\f1ad';
}
.icon-child:before {
    content: '\f1ae';
}
.icon-paw:before {
    content: '\f1b0';
}
.icon-spoon:before {
    content: '\f1b1';
}
.icon-cube:before {
    content: '\f1b2';
}
.icon-cubes:before {
    content: '\f1b3';
}
.icon-behance:before {
    content: '\f1b4';
}
.icon-behance-square:before {
    content: '\f1b5';
}
.icon-steam:before {
    content: '\f1b6';
}
.icon-steam-square:before {
    content: '\f1b7';
}
.icon-recycle:before {
    content: '\f1b8';
}
.icon-automobile:before {
    content: '\f1b9';
}
.icon-car:before {
    content: '\f1b9';
}
.icon-cab:before {
    content: '\f1ba';
}
.icon-taxi:before {
    content: '\f1ba';
}
.icon-tree:before {
    content: '\f1bb';
}
.icon-spotify:before {
    content: '\f1bc';
}
.icon-deviantart:before {
    content: '\f1bd';
}
.icon-soundcloud:before {
    content: '\f1be';
}
.icon-database:before {
    content: '\f1c0';
}
.icon-file-pdf-o:before {
    content: '\f1c1';
}
.icon-file-word-o:before {
    content: '\f1c2';
}
.icon-file-excel-o:before {
    content: '\f1c3';
}
.icon-file-powerpoint-o:before {
    content: '\f1c4';
}
.icon-file-image-o:before {
    content: '\f1c5';
}
.icon-file-photo-o:before {
    content: '\f1c5';
}
.icon-file-picture-o:before {
    content: '\f1c5';
}
.icon-file-archive-o:before {
    content: '\f1c6';
}
.icon-file-zip-o:before {
    content: '\f1c6';
}
.icon-file-audio-o:before {
    content: '\f1c7';
}
.icon-file-sound-o:before {
    content: '\f1c7';
}
.icon-file-movie-o:before {
    content: '\f1c8';
}
.icon-file-video-o:before {
    content: '\f1c8';
}
.icon-file-code-o:before {
    content: '\f1c9';
}
.icon-vine:before {
    content: '\f1ca';
}
.icon-codepen:before {
    content: '\f1cb';
}
.icon-jsfiddle:before {
    content: '\f1cc';
}
.icon-life-bouy:before {
    content: '\f1cd';
}
.icon-life-buoy:before {
    content: '\f1cd';
}
.icon-life-ring:before {
    content: '\f1cd';
}
.icon-life-saver:before {
    content: '\f1cd';
}
.icon-support:before {
    content: '\f1cd';
}
.icon-circle-o-notch:before {
    content: '\f1ce';
}
.icon-ra:before {
    content: '\f1d0';
}
.icon-rebel:before {
    content: '\f1d0';
}
.icon-resistance:before {
    content: '\f1d0';
}
.icon-empire:before {
    content: '\f1d1';
}
.icon-ge:before {
    content: '\f1d1';
}
.icon-git-square:before {
    content: '\f1d2';
}
.icon-git:before {
    content: '\f1d3';
}
.icon-hacker-news:before {
    content: '\f1d4';
}
.icon-y-combinator-square:before {
    content: '\f1d4';
}
.icon-yc-square:before {
    content: '\f1d4';
}
.icon-tencent-weibo:before {
    content: '\f1d5';
}
.icon-qq:before {
    content: '\f1d6';
}
.icon-wechat:before {
    content: '\f1d7';
}
.icon-weixin:before {
    content: '\f1d7';
}
.icon-paper-plane:before {
    content: '\f1d8';
}
.icon-send:before {
    content: '\f1d8';
}
.icon-paper-plane-o:before {
    content: '\f1d9';
}
.icon-send-o:before {
    content: '\f1d9';
}
.icon-history:before {
    content: '\f1da';
}
.icon-circle-thin:before {
    content: '\f1db';
}
.icon-header:before {
    content: '\f1dc';
}
.icon-paragraph:before {
    content: '\f1dd';
}
.icon-sliders:before {
    content: '\f1de';
}
.icon-share-alt:before {
    content: '\f1e0';
}
.icon-share-alt-square:before {
    content: '\f1e1';
}
.icon-bomb:before {
    content: '\f1e2';
}
.icon-futbol-o:before {
    content: '\f1e3';
}
.icon-soccer-ball-o:before {
    content: '\f1e3';
}
.icon-tty:before {
    content: '\f1e4';
}
.icon-binoculars:before {
    content: '\f1e5';
}
.icon-plug:before {
    content: '\f1e6';
}
.icon-slideshare:before {
    content: '\f1e7';
}
.icon-twitch:before {
    content: '\f1e8';
}
.icon-yelp:before {
    content: '\f1e9';
}
.icon-newspaper-o:before {
    content: '\f1ea';
}
.icon-wifi:before {
    content: '\f1eb';
}
.icon-calculator:before {
    content: '\f1ec';
}
.icon-paypal:before {
    content: '\f1ed';
}
.icon-google-wallet:before {
    content: '\f1ee';
}
.icon-cc-visa:before {
    content: '\f1f0';
}
.icon-cc-mastercard:before {
    content: '\f1f1';
}
.icon-cc-discover:before {
    content: '\f1f2';
}
.icon-cc-amex:before {
    content: '\f1f3';
}
.icon-cc-paypal:before {
    content: '\f1f4';
}
.icon-cc-stripe:before {
    content: '\f1f5';
}
.icon-bell-slash:before {
    content: '\f1f6';
}
.icon-bell-slash-o:before {
    content: '\f1f7';
}
.icon-trash:before {
    content: '\f1f8';
}
.icon-copyright:before {
    content: '\f1f9';
}
.icon-at:before {
    content: '\f1fa';
}
.icon-eyedropper:before {
    content: '\f1fb';
}
.icon-paint-brush:before {
    content: '\f1fc';
}
.icon-birthday-cake:before {
    content: '\f1fd';
}
.icon-area-chart:before {
    content: '\f1fe';
}
.icon-pie-chart:before {
    content: '\f200';
}
.icon-line-chart:before {
    content: '\f201';
}
.icon-lastfm:before {
    content: '\f202';
}
.icon-lastfm-square:before {
    content: '\f203';
}
.icon-toggle-off:before {
    content: '\f204';
}
.icon-toggle-on:before {
    content: '\f205';
}
.icon-bicycle:before {
    content: '\f206';
}
.icon-bus:before {
    content: '\f207';
}
.icon-ioxhost:before {
    content: '\f208';
}
.icon-angellist:before {
    content: '\f209';
}
.icon-cc:before {
    content: '\f20a';
}
.icon-ils:before {
    content: '\f20b';
}
.icon-shekel:before {
    content: '\f20b';
}
.icon-sheqel:before {
    content: '\f20b';
}
.icon-meanpath:before {
    content: '\f20c';
}
.icon-buysellads:before {
    content: '\f20d';
}
.icon-connectdevelop:before {
    content: '\f20e';
}
.icon-dashcube:before {
    content: '\f210';
}
.icon-forumbee:before {
    content: '\f211';
}
.icon-leanpub:before {
    content: '\f212';
}
.icon-sellsy:before {
    content: '\f213';
}
.icon-shirtsinbulk:before {
    content: '\f214';
}
.icon-simplybuilt:before {
    content: '\f215';
}
.icon-skyatlas:before {
    content: '\f216';
}
.icon-cart-plus:before {
    content: '\f217';
}
.icon-cart-arrow-down:before {
    content: '\f218';
}
.icon-diamond:before {
    content: '\f219';
}
.icon-ship:before {
    content: '\f21a';
}
.icon-user-secret:before {
    content: '\f21b';
}
.icon-motorcycle:before {
    content: '\f21c';
}
.icon-street-view:before {
    content: '\f21d';
}
.icon-heartbeat:before {
    content: '\f21e';
}
.icon-venus:before {
    content: '\f221';
}
.icon-mars:before {
    content: '\f222';
}
.icon-mercury:before {
    content: '\f223';
}
.icon-intersex:before {
    content: '\f224';
}
.icon-transgender:before {
    content: '\f224';
}
.icon-transgender-alt:before {
    content: '\f225';
}
.icon-venus-double:before {
    content: '\f226';
}
.icon-mars-double:before {
    content: '\f227';
}
.icon-venus-mars:before {
    content: '\f228';
}
.icon-mars-stroke:before {
    content: '\f229';
}
.icon-mars-stroke-v:before {
    content: '\f22a';
}
.icon-mars-stroke-h:before {
    content: '\f22b';
}
.icon-neuter:before {
    content: '\f22c';
}
.icon-genderless:before {
    content: '\f22d';
}
.icon-facebook-official:before {
    content: '\f230';
}
.icon-pinterest-p:before {
    content: '\f231';
}
.icon-whatsapp:before {
    content: '\f232';
}
.icon-server:before {
    content: '\f233';
}
.icon-user-plus:before {
    content: '\f234';
}
.icon-user-times:before {
    content: '\f235';
}
.icon-bed:before {
    content: '\f236';
}
.icon-hotel:before {
    content: '\f236';
}
.icon-viacoin:before {
    content: '\f237';
}
.icon-train:before {
    content: '\f238';
}
.icon-subway:before {
    content: '\f239';
}
.icon-medium:before {
    content: '\f23a';
}
.icon-y-combinator:before {
    content: '\f23b';
}
.icon-yc:before {
    content: '\f23b';
}
.icon-optin-monster:before {
    content: '\f23c';
}
.icon-opencart:before {
    content: '\f23d';
}
.icon-expeditedssl:before {
    content: '\f23e';
}
.icon-battery:before {
    content: '\f240';
}
.icon-battery-4:before {
    content: '\f240';
}
.icon-battery-full:before {
    content: '\f240';
}
.icon-battery-3:before {
    content: '\f241';
}
.icon-battery-three-quarters:before {
    content: '\f241';
}
.icon-battery-2:before {
    content: '\f242';
}
.icon-battery-half:before {
    content: '\f242';
}
.icon-battery-1:before {
    content: '\f243';
}
.icon-battery-quarter:before {
    content: '\f243';
}
.icon-battery-0:before {
    content: '\f244';
}
.icon-battery-empty:before {
    content: '\f244';
}
.icon-mouse-pointer:before {
    content: '\f245';
}
.icon-i-cursor:before {
    content: '\f246';
}
.icon-object-group:before {
    content: '\f247';
}
.icon-object-ungroup:before {
    content: '\f248';
}
.icon-sticky-note:before {
    content: '\f249';
}
.icon-sticky-note-o:before {
    content: '\f24a';
}
.icon-cc-jcb:before {
    content: '\f24b';
}
.icon-cc-diners-club:before {
    content: '\f24c';
}
.icon-clone:before {
    content: '\f24d';
}
.icon-balance-scale:before {
    content: '\f24e';
}
.icon-hourglass-o:before {
    content: '\f250';
}
.icon-hourglass-1:before {
    content: '\f251';
}
.icon-hourglass-start:before {
    content: '\f251';
}
.icon-hourglass-2:before {
    content: '\f252';
}
.icon-hourglass-half:before {
    content: '\f252';
}
.icon-hourglass-3:before {
    content: '\f253';
}
.icon-hourglass-end:before {
    content: '\f253';
}
.icon-hourglass:before {
    content: '\f254';
}
.icon-hand-grab-o:before {
    content: '\f255';
}
.icon-hand-rock-o:before {
    content: '\f255';
}
.icon-hand-paper-o:before {
    content: '\f256';
}
.icon-hand-stop-o:before {
    content: '\f256';
}
.icon-hand-scissors-o:before {
    content: '\f257';
}
.icon-hand-lizard-o:before {
    content: '\f258';
}
.icon-hand-spock-o:before {
    content: '\f259';
}
.icon-hand-pointer-o:before {
    content: '\f25a';
}
.icon-hand-peace-o:before {
    content: '\f25b';
}
.icon-trademark:before {
    content: '\f25c';
}
.icon-registered:before {
    content: '\f25d';
}
.icon-creative-commons:before {
    content: '\f25e';
}
.icon-gg:before {
    content: '\f260';
}
.icon-gg-circle:before {
    content: '\f261';
}
.icon-tripadvisor:before {
    content: '\f262';
}
.icon-odnoklassniki:before {
    content: '\f263';
}
.icon-odnoklassniki-square:before {
    content: '\f264';
}
.icon-get-pocket:before {
    content: '\f265';
}
.icon-wikipedia-w:before {
    content: '\f266';
}
.icon-safari:before {
    content: '\f267';
}
.icon-chrome:before {
    content: '\f268';
}
.icon-firefox:before {
    content: '\f269';
}
.icon-opera:before {
    content: '\f26a';
}
.icon-internet-explorer:before {
    content: '\f26b';
}
.icon-television:before {
    content: '\f26c';
}
.icon-tv:before {
    content: '\f26c';
}
.icon-contao:before {
    content: '\f26d';
}
.icon-500px:before {
    content: '\f26e';
}
.icon-amazon:before {
    content: '\f270';
}
.icon-calendar-plus-o:before {
    content: '\f271';
}
.icon-calendar-minus-o:before {
    content: '\f272';
}
.icon-calendar-times-o:before {
    content: '\f273';
}
.icon-calendar-check-o:before {
    content: '\f274';
}
.icon-industry:before {
    content: '\f275';
}
.icon-map-pin:before {
    content: '\f276';
}
.icon-map-signs:before {
    content: '\f277';
}
.icon-map-o:before {
    content: '\f278';
}
.icon-map:before {
    content: '\f279';
}
.icon-commenting:before {
    content: '\f27a';
}
.icon-commenting-o:before {
    content: '\f27b';
}
.icon-houzz:before {
    content: '\f27c';
}
.icon-vimeo:before {
    content: '\f27d';
}
.icon-black-tie:before {
    content: '\f27e';
}
.icon-fonticons:before {
    content: '\f280';
}
.icon-reddit-alien:before {
    content: '\f281';
}
.icon-edge:before {
    content: '\f282';
}
.icon-credit-card-alt:before {
    content: '\f283';
}
.icon-codiepie:before {
    content: '\f284';
}
.icon-modx:before {
    content: '\f285';
}
.icon-fort-awesome:before {
    content: '\f286';
}
.icon-usb:before {
    content: '\f287';
}
.icon-product-hunt:before {
    content: '\f288';
}
.icon-mixcloud:before {
    content: '\f289';
}
.icon-scribd:before {
    content: '\f28a';
}
.icon-pause-circle:before {
    content: '\f28b';
}
.icon-pause-circle-o:before {
    content: '\f28c';
}
.icon-stop-circle:before {
    content: '\f28d';
}
.icon-stop-circle-o:before {
    content: '\f28e';
}
.icon-shopping-bag:before {
    content: '\f290';
}
.icon-shopping-basket:before {
    content: '\f291';
}
.icon-hashtag:before {
    content: '\f292';
}
.icon-bluetooth:before {
    content: '\f293';
}
.icon-bluetooth-b:before {
    content: '\f294';
}
.icon-percent:before {
    content: '\f295';
}
.icon-gitlab:before {
    content: '\f296';
}
.icon-wpbeginner:before {
    content: '\f297';
}
.icon-wpforms:before {
    content: '\f298';
}
.icon-envira:before {
    content: '\f299';
}
.icon-universal-access:before {
    content: '\f29a';
}
.icon-wheelchair-alt:before {
    content: '\f29b';
}
.icon-question-circle-o:before {
    content: '\f29c';
}
.icon-blind:before {
    content: '\f29d';
}
.icon-audio-description:before {
    content: '\f29e';
}
.icon-volume-control-phone:before {
    content: '\f2a0';
}
.icon-braille:before {
    content: '\f2a1';
}
.icon-assistive-listening-systems:before {
    content: '\f2a2';
}
.icon-american-sign-language-interpreting:before {
    content: '\f2a3';
}
.icon-asl-interpreting:before {
    content: '\f2a3';
}
.icon-deaf:before {
    content: '\f2a4';
}
.icon-deafness:before {
    content: '\f2a4';
}
.icon-hard-of-hearing:before {
    content: '\f2a4';
}
.icon-glide:before {
    content: '\f2a5';
}
.icon-glide-g:before {
    content: '\f2a6';
}
.icon-sign-language:before {
    content: '\f2a7';
}
.icon-signing:before {
    content: '\f2a7';
}
.icon-low-vision:before {
    content: '\f2a8';
}
.icon-viadeo:before {
    content: '\f2a9';
}
.icon-viadeo-square:before {
    content: '\f2aa';
}
.icon-snapchat:before {
    content: '\f2ab';
}
.icon-snapchat-ghost:before {
    content: '\f2ac';
}
.icon-snapchat-square:before {
    content: '\f2ad';
}
.icon-pied-piper:before {
    content: '\f2ae';
}
.icon-first-order:before {
    content: '\f2b0';
}
.icon-yoast:before {
    content: '\f2b1';
}
.icon-themeisle:before {
    content: '\f2b2';
}
.icon-google-plus-circle:before {
    content: '\f2b3';
}
.icon-google-plus-official:before {
    content: '\f2b3';
}
.icon-fa:before {
    content: '\f2b4';
}
.icon-font-awesome:before {
    content: '\f2b4';
}
.icon-handshake-o:before {
    content: '\f2b5';
}
.icon-envelope-open:before {
    content: '\f2b6';
}
.icon-envelope-open-o:before {
    content: '\f2b7';
}
.icon-linode:before {
    content: '\f2b8';
}
.icon-address-book:before {
    content: '\f2b9';
}
.icon-address-book-o:before {
    content: '\f2ba';
}
.icon-address-card:before {
    content: '\f2bb';
}
.icon-vcard:before {
    content: '\f2bb';
}
.icon-address-card-o:before {
    content: '\f2bc';
}
.icon-vcard-o:before {
    content: '\f2bc';
}
.icon-user-circle:before {
    content: '\f2bd';
}
.icon-user-circle-o:before {
    content: '\f2be';
}
.icon-user-o:before {
    content: '\f2c0';
}
.icon-id-badge:before {
    content: '\f2c1';
}
.icon-drivers-license:before {
    content: '\f2c2';
}
.icon-id-card:before {
    content: '\f2c2';
}
.icon-drivers-license-o:before {
    content: '\f2c3';
}
.icon-id-card-o:before {
    content: '\f2c3';
}
.icon-quora:before {
    content: '\f2c4';
}
.icon-free-code-camp:before {
    content: '\f2c5';
}
.icon-telegram:before {
    content: '\f2c6';
}
.icon-thermometer:before {
    content: '\f2c7';
}
.icon-thermometer-4:before {
    content: '\f2c7';
}
.icon-thermometer-full:before {
    content: '\f2c7';
}
.icon-thermometer-3:before {
    content: '\f2c8';
}
.icon-thermometer-three-quarters:before {
    content: '\f2c8';
}
.icon-thermometer-2:before {
    content: '\f2c9';
}
.icon-thermometer-half:before {
    content: '\f2c9';
}
.icon-thermometer-1:before {
    content: '\f2ca';
}
.icon-thermometer-quarter:before {
    content: '\f2ca';
}
.icon-thermometer-0:before {
    content: '\f2cb';
}
.icon-thermometer-empty:before {
    content: '\f2cb';
}
.icon-shower:before {
    content: '\f2cc';
}
.icon-bath:before {
    content: '\f2cd';
}
.icon-bathtub:before {
    content: '\f2cd';
}
.icon-s15:before {
    content: '\f2cd';
}
.icon-podcast:before {
    content: '\f2ce';
}
.icon-window-maximize:before {
    content: '\f2d0';
}
.icon-window-minimize:before {
    content: '\f2d1';
}
.icon-window-restore:before {
    content: '\f2d2';
}
.icon-times-rectangle:before {
    content: '\f2d3';
}
.icon-window-close:before {
    content: '\f2d3';
}
.icon-times-rectangle-o:before {
    content: '\f2d4';
}
.icon-window-close-o:before {
    content: '\f2d4';
}
.icon-bandcamp:before {
    content: '\f2d5';
}
.icon-grav:before {
    content: '\f2d6';
}
.icon-etsy:before {
    content: '\f2d7';
}
.icon-imdb:before {
    content: '\f2d8';
}
.icon-ravelry:before {
    content: '\f2d9';
}
.icon-eercast:before {
    content: '\f2da';
}
.icon-microchip:before {
    content: '\f2db';
}
.icon-snowflake-o:before {
    content: '\f2dc';
}
.icon-superpowers:before {
    content: '\f2dd';
}
.icon-wpexplorer:before {
    content: '\f2de';
}
.icon-meetup:before {
    content: '\f2e0';
}
