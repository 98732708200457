.featherlight-close,
.featherlight-previous,
.featherlight-next {
    display: none;
}

.featherlight:last-of-type {
    background: rgba(255, 255, 255, .8);
}

.featherlight-content {
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);
}
