#login {
    position: relative;

    min-height: 100vh;
    padding: 4rem;

    color: white;
    .bg-image,
    .bg-overlay {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }
    .bg-image {
        z-index: -2;

        background-image: url('../assets/images/login/login-bg-sm.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .bg-overlay {
        z-index: -1;

        background-color: rgba(27, 54, 93, .9);
    }
    img {
        display: block;

        max-width: 25rem;
        margin: 0 auto;
    }
    h1 {
      text-align: center;
    }
    h2 {
        margin: 2rem 0 4rem;

        text-align: center;
    }
    input:focus {
        outline: none;
    }
    input[type='text'],
    input[type='email'],
    input[type='password'] {
        font-family: $font__SourceSans;
        font-size: 1.8rem;
        line-height: 2.3rem;

        display: block;

        width: 100%;
        max-width: 25rem;
        margin: 0 auto 4rem;
        padding: 0 0 .5rem;

        color: white;
        border: none;
        border-bottom: .2rem solid white;
        background-color: transparent;

        border-radius: 0;
    }
    input[type='checkbox'] {
      display: inline;
      width: auto;
      margin-right: 1.5rem;
    }
    input::placeholder {
        color: white;
    }
    input::-webkit-input-placeholder {
        color: white;
    }
    input::-moz-placeholder {
        color: white;
    }
    input:-ms-input-placeholder {
        color: white;
    }
    input:-moz-placeholder {
        color: white;
    }
    fieldset#subscription {
      label {
        display: block;
      }

      div.subscription-description {
        font-size: 1.5rem;
        line-height: 1.7rem;
        margin-left: 4rem;
        margin-bottom: 1.5rem;
        font-style: italic;
      }
    }
    p {
        max-width: 50rem;
        margin: 0 auto 4rem;

        text-align: center;

        color: white;
    }
    .button {
        display: block;
        cursor: pointer;

        margin: 0 auto;
        margin-bottom: 0.5rem;

        background-color: transparent;
        &:hover {
            background-color: white;
        }
    }
    .forgot-password {
        margin-top: 2rem;

        text-align: center;
        a {
            font-family: $font__PFDmed;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1;

            color: $highlight_blue;
            &:hover {
                color: darken($highlight_blue, 10);
            }
        }
    }
    #login_messages p {
        max-width: 50rem;
        margin: -2rem auto 1rem;
        color: $red;
    }

    .job-row {
        padding: 0 1.6rem 1.6rem 1.6rem;
        border: 1px solid white;
        margin-bottom: 1rem;
        h3 {
            font-size: 3rem;
        }
    }
}
.login-footer {
    display: none;
}
@media (min-width: 375px) {
    #login {
        padding: 6rem;
        .bg-image {
            background-image: url('../assets/images/login/login-bg-md.jpg');
        }
    }
}
@media (min-width: 768px) {
    #login {
        display: flex;

        align-items: center;
        justify-content: center;
        .bg-image {
            background-image: url('../assets/images/login/login-bg-lg.jpg');
        }
    }
}
