// mobile body content styles
.main.visible-xs {
  position: relative;

  min-height: calc(100vh - 36.3rem);
  margin: -5rem 0 6.3rem;
  .footer-links {
    z-index: 99999;

    width: 100%;

    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;

    ul {
      height: 6.3rem;
      margin: 0;
      padding: 0;

      background-color: $dark_blue;
      li {
        display: inline-block;
        float: left;

        width: 50%;
        &:first-of-type {
          border-right: .1rem solid $blue;
        }
        &:last-of-type {
          border-left: .1rem solid $blue;
        }
        a {
          font-family: $font__PFD;
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 1;

          display: block;

          padding: 2.25rem 1.5rem;

          text-align: center;
          text-transform: uppercase;

          color: white;
        }
        &.has-notification {
          a span {
            display: inline-block;

            width: 1rem;
            height: 1rem;
            margin: 0 1.2rem .1rem 0;

            border-radius: 10rem;
            background-color: $highlight_blue;
          }
        }
      }
    }
  }
}
.mobile-link-cards {
  .link-card {
    position: relative;

    display: block;

    margin-bottom: 1.5rem;
    padding: 3rem;

    cursor: pointer;

    background-color: white;
    &.has-notification:before {
      width: 1rem;
      height: 100%;

      content: '';

      background-color: $highlight_blue;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

    }
    h3 {
      margin: 0;
    }
    h3 + p {
      margin-top: 1rem;
    }
    p {
      margin-bottom: 0;
    }
  }
}

// body content styles
.main.hidden-xs {
  section {
    padding: 6rem 0;
  }
  section.messages-team {
    background-color: $light_grey_alt;
  }
  section:not(.messages-team) .section-wrapper,
  .messages-team .mobile-content {
    padding: 3rem 1.5rem;

    background-color: white;
    box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, .24);
    > .row {
      margin: 0;
    }
    h3 {
      margin-top: 0;
    }
  }
}
.section-heading {
  display: none;
}

@media (min-width: 768px) {
  .section-heading {
    position: relative;

    display: block;

    padding-bottom: 3rem;
    p:last-of-type {
      margin-bottom: 0;
    }
    &:after {
      width: calc(100% + 6rem);
      height: .2rem;

      content: '';

      background-color: $light_grey_alt;

      position: absolute;
      bottom: 0;
      left: -3rem;

    }
  }
  .messages-team .section-wrapper {
    > .row {
      display: flex;
      .mobile-content {
        height: 100%;
      }
    }
  }
}

@media (min-width: 992px) {
  .main.hidden-xs {
    .section-wrapper {
      margin: 0 8.33333333%;
    }
  }
}
