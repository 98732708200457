nav.navbar {
    position: fixed;
    z-index: 9999999;
    top: 0;

    overflow-y: scroll;

    width: 100%;
    height: 6.3rem;
    margin-bottom: 0;

    border: 0;
    border-radius: 0;
    background-color: transparent;
    &.open {
        z-index: 9999999;

        width: 100%;
        height: 100%;

        position: fixed;
        top: 0;
        left: 0;
        .navbar-header {
            z-index: 999999;

            position: fixed;
            top: 0;
            right: 0;
            left: 0;
        }
        .main-nav {
            overflow-y: scroll;

            height: 100%;
            margin-top: 6.3rem;

            opacity: 1;
        }
    }
    .navbar-header {
        width: 100%;
        height: 6.3rem;

        transition: all .3s ease-in-out;
        text-align: center;

        background-color: white;
        box-shadow: 0 0 .3rem rgba(0, 0, 0, .24);
        &.blue {
            background-color: $dark_blue;
        }
        &.white {
            z-index: 9999999;

            border-bottom: .1rem solid $light_grey_alt;
            .nav li a {
                color: $dark_blue;
            }
            .navbar-toggle .icon-bar {
                background-color: $dark_blue;
            }
            &.open {
                border-bottom-color: rgba(0, 0, 0, .24);
                background-color: $dark_blue;
                .navbar-toggle .icon-bar {
                    background-color: white;
                }
            }
        }
        &.open {
            .navbar-brand {
                pointer-events: auto;

                opacity: 1;
            }
            .nav {
                pointer-events: none;

                opacity: 0;
            }
            .navbar-toggle {
                .icon-bar:nth-of-type(2) {
                    transform: rotate(-45deg);
                }
                .icon-bar:nth-of-type(3) {
                    opacity: 0;
                }
                .icon-bar:nth-of-type(4) {
                    margin-top: -.7rem;

                    transform: rotate(45deg);
                }
            }
        }
        .nav {
            width: calc(100% - 5.5rem);
            margin: 0;
            padding: 0 0 0 1rem;

            list-style: none;

            transition: all .3s ease-in-out;
            text-align: right;
            white-space: nowrap;

            position: absolute;
            top: .55rem;
            left: 0;
            li {
                position: relative;

                display: inline-block;
                a {
                    display: block;

                    padding: 1.5rem .75rem;

                    color: white;
                    &:hover,
                    &:active,
                    &:focus {
                        text-decoration: none;

                        outline: 0;
                        background-color: transparent;
                    }
                }
                &.has-notification {
                    &:after {
                        width: .8rem;
                        height: .8rem;

                        content: '';

                        border-radius: 10rem;
                        background-color: $highlight_blue;

                        position: absolute;
                        top: 1rem;
                        right: -.2rem;
                    }
                }
            }
            .home-link {
                float: left;

                margin-left: .75rem;
            }
        }
    }
    .navbar-brand {
        display: inline-block;

        width: 21.5rem;
        height: 100%;
        max-height: 10rem;
        padding: 1.2rem 0 1.2rem 3rem;

        transition: all .3s ease-in-out;
        pointer-events: none;

        opacity: 0;
        background-color: transparent;
    }
    .navbar-toggle {
        min-height: 4.2rem;
        margin: 0 .5rem 0 0;
        padding: 1.5rem 1rem;

        border: none;

        position: absolute;
        top: .85rem;
        right: 1.5rem;
        &:focus,
        &:hover {
            background: transparent;
        }
        .icon-bar {
            width: 1.4rem;
            height: .2rem;

            transition: all .3s ease-in-out;

            border-radius: 0;
            background-color: white;
            + .icon-bar {
                margin-top: .3rem;
            }
        }
    }
    .main-nav {
        overflow-y: scroll;

        width: 100%;
        height: 0;
        margin-top: 0;

        transition: all .3s ease-in-out;

        opacity: 0;
        border-top: none;
        background-color: white;
        background-color: $dark_blue;
        .nav {
            width: 100%;
            margin: 0;
            li {
                position: relative;

                display: block;
                &:not(:last-child):after {
                    width: 100%;

                    content: '';

                    border-bottom: .1rem solid $blue;

                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                a {
                    font-family: $font__PFDmed;
                    font-size: 2.6rem;
                    font-weight: 500;
                    line-height: 2.7rem;

                    display: block;

                    padding: 1.5rem 3rem;

                    text-transform: uppercase;

                    color: white;
                    &.active {
                        background-color: $blue;
                    }
                    &:hover {
                        background-color: $dark_blue;
                    }
                }
            }
        }
        .nav:first-of-type {
            padding-bottom: 6rem;
        }
        .nav:nth-of-type(2) {
            overflow: hidden;

            height: 6.3rem;

            text-align: center;

            position: absolute;
            bottom: -6.3rem;
            left: 0;
            li {
                display: inline-block;
                float: left;

                width: 50%;
                &:after {
                    display: none;
                }
                &:last-child {
                    border-left: .1rem solid $blue;
                }
                a {
                    font-size: 1.8rem;
                    line-height: 1;

                    padding: 2.25rem;
                }
            }
        }
    }
    .nav-wrapper {
        position: relative;

        min-height: calc(100vh - 12.6rem);
        margin-bottom: 6.3rem;
        &:after {
            width: 100%;
            height: .1rem;

            content: '';

            background-color: $blue;

            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}


@media screen and (min-width: 768px) {
    nav.navbar {
        position: relative;
        z-index: auto!important;

        overflow: hidden;

        height: 10rem;
        .row {
            overflow: hidden;
        }
        .navbar-header {
            width: 31rem;
            height: 10rem;

            background-color: transparent;
            box-shadow: none;
            .nav {
                display: none;
            }
        }
        .navbar-brand {
            display: block;

            width: auto;
            margin-left: 0!important;
            padding: 2.7rem 3rem;

            opacity: 1;
            img {
                width: 25rem;
                max-width: 25rem;
            }
        }
        .main-nav {
            display: inline-block!important;
            float: none;
            overflow: hidden;

            width: calc(100% - 31rem);
            height: 10rem;

            opacity: 1;
            background-color: white;
            .primary.nav,
            .secondary.nav {
                float: none;

                text-align: right;
                li {
                    display: inline-block;
                    float: none;
                    &:after {
                        display: none;
                    }
                }
            }
            .primary.nav {
                height: 7.5rem;
                padding-right: 1.5rem;
                li {
                    margin-top: 2.7rem;
                    a {
                        font-size: 1.8rem;
                        line-height: 1;

                        position: relative;

                        padding: 1.5rem;

                        color: $red;
                        &:after {
                            width: calc(100% - 3rem);
                            height: .2rem;

                            content: '';
                            transition: background-color .3s ease-in-out;

                            background-color: transparent;

                            position: absolute;
                            bottom: 1rem;
                            left: 1.5rem;
                        }
                        &.active,
                        &:hover {
                            text-decoration: none;

                            color: $red;
                            background-color: transparent;
                            &:after {
                                background-color: $red;
                            }
                        }
                    }
                }
            }
            .secondary.nav {
                height: 2.5rem;
                padding-right: 2rem;

                background: -webkit-repeating-linear-gradient(135deg, #f8f8f8, #f8f8f8 2px, #fff 1px, #fff 8px);
                background:         repeating-linear-gradient( -45deg, $light-grey, $light-grey 2px, #fff 1px, #fff 8px);
                li {
                    a {
                        font-family: $font__SourceSans;
                        font-size: 1.4rem;
                        font-weight: 700;
                        line-height: 1;

                        padding: .5rem 1rem;

                        text-decoration: none;
                        text-transform: uppercase;

                        color: $dark_blue;
                        &:hover,
                        &:active,
                        &:focus {
                            color: $blue;
                            background-color: transparent;
                        }
                    }
                    a.active {
                        background-color: transparent;
                    }
                }
                .logout-link {
                    position: relative;

                    padding-left: 2.6rem;
                    i {
                      position: absolute;
                      top: .5rem;
                      left: 1rem;
                    }
                }
            }
            .nav-wrapper {
                min-height: 0;
                margin-bottom: 0;
                &:after {
                    display: none;
                }
            }
        }
    }
}
