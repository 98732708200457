.modal#account {
    h3 {
        margin: 0 0 3rem;
    }
    p.contact__change_password {
        text-align: center;
    }
}

.account-contacts {
    .contact {
        padding: 3rem 0;

        border-top: .2rem solid $light_grey_alt;
        h5 {
            margin-left: 2rem;
            margin-bottom: 5px;
            font-weight: 600;
            line-height: 1.6rem;
            text-transform: uppercase;
        }
    }
    .contact__wrapper {
        position: relative;

        display: flex;

        min-height: 5.5rem;
        margin-bottom: 2rem;
        padding-left: 6.5rem;

        align-items: center;
        h3 {
            margin: 0 !important;
        }
    }
    .contact__image {
        position: absolute;
        left: 0;
        top: 0;

        width: 5.5rem;
        height: 5.5rem;

        border-radius: 10rem;
        background-position: top;
        background-size: cover;
    }
    p {
        position: relative;

        margin: 0 0 0.5rem 2rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        &.contact__button {
            text-align: center;
            margin-left: 0;

            a { 
                width: 21rem;
            }
        }
    }
    .contact__edit,
    .contact__phone,
    .contact__email {
        padding-left: 3rem;
        span {
            font-size: 1.1rem;
//            font-style: italic;
        }
    }
    .contact__casl {
        padding-left: 3rem;
        font-size: 1rem;
    }
    .contact__view {
        margin-top: 2rem;
        a {
            font-size: 1.4rem;
            line-height: 1.8rem;

            color: $highlight_blue;
            &:hover,
            &:focus,
            &:active {
                color: darken($highlight_blue, 10);
            }
        }
    }
    i {
        position: absolute;
        top: .3rem;
        left: 0;
    }
    a {
        color: $dark_blue;
        &.button {
            margin-top: 1rem;
        }
    }
}

@media (min-width: 992px) {
    .modal#account {
        h3 {
            margin: 4rem 8.33333333%;
        }
    }
    .account-contacts {
        .contact__view {
            a {
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }
    }
}
