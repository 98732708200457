/*
 * Common CSS Things
 */

body {
    height: 100%;
    min-height: 100vh;

    background-color: $light_grey_alt;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// body.modal-open {
//     overflow: hidden;
// }

// H1 - PFDin, Medium, Uppercase, White: 48px/30px
h1 {
    font-family: $font__PFDmed;
    font-size: 4.8rem;
    font-weight: 500;
    line-height: 4.3rem;

    text-transform: uppercase;

    color: white;
}

// H2 - PFDin, Medium, Uppercase, White: 42px/43px
h2 {
    font-family: $font__PFDmed;
    font-size: 4.2rem;
    font-weight: 500;
    line-height: 4.3rem;

    text-transform: uppercase;

    color: white;
}

// H3 - PFDin, Medium, Uppercase, White: 26px/27px
h3 {
    font-family: $font__PFDmed;
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 2.7rem;

    text-transform: uppercase;

    color: white;
}

// H4 - PFDin, Medium, Blue: 23px/23px
h4 {
    font-family: $font__PFDmed;
    font-size: 2.3rem;
    font-weight: 500;
    line-height: 2.3rem;

    color: $dark_blue;
}

// Body - Source Sans Pro, Regular: 16px/20px
body,
p {
    font-family: $font__SourceSans;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;

    color: $blue;
}

.small,
.small p {
    font-size: 1.4rem;
    line-height: 1.8rem;
}

.large,
.large p {
    font-size: 1.8rem;
    line-height: 2.3rem;
}

strong {
    font-weight: 700;
}

a {
    font-weight: 600;

    cursor: pointer;

    color: darken($highlight_blue, 10);

    @extend %transition;
    &:active,
    &:focus,
    &:hover {
        text-decoration: none;

        color: $highlight_blue;
        outline: none;
    }
}

.button {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1;

    display: inline-block;

    padding: 1.5rem 6rem;

    text-align: center;
    text-transform: uppercase;

    color: white;
    border: .2rem solid white;
    border-radius: 10rem;

    @extend %transition;
    &:hover {
        color: $dark_blue;
        background-color: white;
    }
    &.blue {
        font-size: 1.6rem;

        padding: 1rem 3rem;

        color: $highlight_blue;
        border-color: $highlight_blue;
        &:hover {
            color: white;
            background-color: $highlight_blue;
        }
    }
}

.red,
.red p {
    color: $dark_red;
}

.white,
.white p {
    color: white;
}

.blue,
.blue p {
    color: $dark_blue;
}

img {
    max-width: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.spin {
  display: inline-block;
  animation: spin 1.5s infinite linear;
}

@media (min-width: 768px) {
    body {
        background-color: $light-grey;
    }

    body,
    p {
        font-size: 1.8rem;
        line-height: 2.3rem;
    }

    a[href^='tel:'] {
        pointer-events: none;
    }
}


/***** MARGINS *****/

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}
