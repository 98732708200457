footer {
    position: relative;

    display: none;

    padding-bottom: 8.4rem;

    background-color: $blue;
    &:before,
    &:after {
        position: absolute;
        right: 0;
        left: 0;

        width: 100%;

        content: '';
    }
    &:before {
        bottom: 8rem;

        height: .4rem;

        background-color: #652e46;
    }
    &:after {
        bottom: 0;

        height: 8rem;

        background-color: $dark_blue;
    }
    p {
        font-size: 1.6rem;
        line-height: 2rem;

        margin: 0;
        padding: 3rem 0;

        text-align: center;

        color: white;
    }
}

@media (min-width: 768px) {
    footer {
        display: block;
    }
}
