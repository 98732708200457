.notifications {
    position: fixed;
    top: 20px;
    z-index: 9999999;
    width: 100%;

    .alert {
        width: 90%;
        max-width: 400px;
    }
}